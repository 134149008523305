import React from 'react';
import FooterImage from 'assets/images/footer/footer-image.png';
import FooterLogoIcon from 'assets/icons/footer/footer-logo.svg';
import { links } from 'components/Header/mockData';

// styles
import './footer.scss';

export const Footer = () => {
  const scrollToSection = () => {
    const sectionRequisition = document.getElementById('#form');
    if (sectionRequisition) {
      sectionRequisition.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDownloadFile = () => {
    const fileUrl = '../../assets/images/footer/footer-image.png';
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <footer className="footer">
      <div className="footer__image-wrapper">
        <img className="footer__image" src={FooterImage} alt="footer image" />
      </div>

      <div className="footer__info">
        <div className="info__wrapper">
          <div className="left">
            <img className="left__image" src={FooterLogoIcon} alt="footer logo" />
            <div className="left__text">
              <p className="left__text-title">60+</p>
              <p className="left__text-subtitle">Общение. Интересы. Инвестиции.</p>
            </div>
          </div>

          <div className="links">
            {links.map(({ name, scrollTo }) => {
              return (
                <a key={name} href={scrollTo} className="link">
                  {name}
                </a>
              );
            })}
          </div>

          <div className="right">
            <button onClick={scrollToSection} className="right__button">
              Оставить заявку
            </button>
            <p onClick={handleDownloadFile} className="right__title">
              Политика конфиденциальности
            </p>
            <p onClick={handleDownloadFile} className="right__subtitle">
              Условия использования
            </p>
          </div>
        </div>

        <p className="bottom__text">© 2023 60+ App. Все права защищены</p>
      </div>
    </footer>
  );
};

export const images = [
  {
    id: '#1',
    classPrefix: '_old',
  },
  {
    id: '#2',
    classPrefix: '_kitchen',
  },
  {
    id: '#3',
    classPrefix: '_pretty',
  },
  {
    id: '#4',
    classPrefix: '_two',
  },
  {
    id: '#5',
    classPrefix: '_dog',
  },
  {
    id: '#6',
    classPrefix: '_young',
  },
];

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { partnersIcons, partnersLogos } from './mockData';

// styles
import './partners.scss';
import 'swiper/css';

export const Partners = () => {
  return (
    <section id="partners" className="partners">
      <h3 className="partners__title">Наши партнеры</h3>

      <div className="partners__logos">
        {partnersLogos.map(({ id, prefix }) => {
          return <div key={id} className={`partners__logos-item partners__logos-item${prefix}`} />;
        })}
      </div>

      <div className="partners-mobile__logos">
        <Swiper className="partners-mobile__logos-slider" slidesPerView={3} loop={true} centeredSlides={true} spaceBetween={30}>
          {partnersIcons.map(({ id, Icon }) => {
            return (
              <SwiperSlide key={id}>
                <Icon />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

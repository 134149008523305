import React, { ChangeEvent, useState } from 'react';

// styles
import './email.scss';
import { IErrors, IModalState, emailInputError, isEmail, modalTextError, modalTextSuccess } from './helper';
import { EmailModal } from 'components/EmailModal/EmailModal';

export const Email = () => {
  const [email, setEmail] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({});

  const [isModalOpen, setIsModalOpen] = useState<IModalState>({
    isOpen: false,
    text: modalTextSuccess,
  });

  const getClubFromURLParameters = () => {
    const fullUrl = window.location.href;
    const urlObject = new URL(fullUrl);
    const searchParams = new URLSearchParams(urlObject.search);
    return searchParams.get('utm_source');
  };

  const sendEmail = async (dataEmail: string) => {
    const URL = '/api';

    const club = getClubFromURLParameters();

    const reqBody = club ? { emailForAdmin: dataEmail, utmPoint: club } : { emailForAdmin: dataEmail };

    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      };

      const response = await fetch(URL, requestOptions);

      if (!response.ok && response.status === 409) {
        setIsModalOpen({ isOpen: true, text: modalTextError });
      }

      const data = await response.json();

      if (data.id) {
        setIsModalOpen({ isOpen: true, text: modalTextSuccess });
      }
    } catch (error) {
      console.error('ERROR:', error);
    }
  };

  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;

    setEmail(newEmail);

    if (newEmail && !isEmail(newEmail)) {
      setErrors({ email: emailInputError });
      return;
    }
    if (!newEmail || isEmail(newEmail)) {
      setErrors({});
    }
  };

  const handleSubmitEmail = () => {
    if (email && isEmail(email)) {
      sendEmail(email);
      setEmail('');
    } else {
      setErrors({ email: emailInputError });
    }
  };

  return (
    <section id="#form" className="email">
      <div className="email__wrapper">
        <h3 className="email__info-mobile-title">Оставьте заявку на приложение</h3>
        <div className="email__image" />
        <div className="email__info">
          <h3 className="email__info-title">Оставьте заявку на приложение</h3>
          <input
            value={email}
            onChange={inputChange}
            className={`email__info-input ${errors.email ? 'error' : ''}`}
            placeholder="Введите адрес почты"
          />
          {errors?.email && <p className="error-text">{errors?.email}</p>}
          <button type="submit" onClick={handleSubmitEmail} className="email__info-button">
            Оставить заявку
          </button>
          <p className="email__info-text">Наш менеджер свяжется с вами в ближайшее время</p>
        </div>
      </div>
      <EmailModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </section>
  );
};

export interface IErrors {
  email?: string;
}

export interface IModalState {
  isOpen: boolean;
  text: string;
}

export const isEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const modalTextSuccess = 'Ваша заявка принята. Наш менеджер свяжется с вами в ближайшее время.';
export const modalTextError = 'Такой e-mail уже существует';
export const emailInputError = 'Некорректный формат электронной почты';

import React, { useState } from 'react';
import { BurgerMenu } from 'components/BurgerMenu/BurgerMenu';
import BurgerIcon from 'components/BurgerIcon/BurgerIcon';
import Logo from 'assets/images/main-page/Logo.png';
import { links } from './mockData';

// styles
import './header.scss';

export const Header = () => {
  const [isActiveBurgerMenu, setIsActiveBurgerMenu] = useState(false);

  const handleCloseBurgerMenu = () => {
    setIsActiveBurgerMenu(false);
  };

  const handleToggleBurgerMenu = () => {
    setIsActiveBurgerMenu(!isActiveBurgerMenu);
  };

  const handleScrollToSection = () => {
    const sectionRequisition = document.getElementById('#form');
    if (sectionRequisition) {
      sectionRequisition.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header id="header" className="header">
      <nav className="header__navbar">
        {links.map(({ name, scrollTo }) => {
          return (
            <a href={scrollTo} key={name} className="navbar__link">
              {name}
            </a>
          );
        })}
        <button className="navbar__button" onClick={handleScrollToSection}>
          Оставить заявку
        </button>
      </nav>

      <div className="mobile__header">
        <p className="mobile__header-title">
          <a href="#header">60+ App</a>
        </p>
        <span className="mobile__header-icon" onClick={handleToggleBurgerMenu}>
          <BurgerIcon isOpen={!isActiveBurgerMenu} />
        </span>
      </div>
      <BurgerMenu isOpen={isActiveBurgerMenu} handleCloseBurgerMenu={handleCloseBurgerMenu} />

      <div className="header__info">
        <img className="info__logo" src={Logo} alt="App logo" />
        <h1 className="info__title">
          Социальная сеть<span className="info__title-age">60+</span>
        </h1>
        <h2 className="info__title-second">60+</h2>
        <h3 className="info__subtitle">Общение. Интересы. Инвестиции.</h3>
        <button className="info__button" onClick={handleScrollToSection}>
          Оставить заявку
        </button>
      </div>
    </header>
  );
};

import React from 'react';
import { MobileScreenSecondSlider } from './MobileScreenSecondSlider/MobileScreenSecondSlides';

// styles
import './mobileScreenSecond.scss';
import 'swiper/css/effect-coverflow';
import 'swiper/css';

export const MobileScreenSecond = () => {
  return (
    <>
      <section className="mobile-screenSecond">
        <div className="mobile-screenSecond_item first__screen" />
        <div className="mobile-screenSecond_item second__screen" />
        <div className="mobile-screenSecond_item third__screen" />
      </section>

      <div className="screenSecond-slider">
        <MobileScreenSecondSlider />
      </div>
    </>
  );
};

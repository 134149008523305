import React from 'react';
import {
  MobileScreenSecond,
  Troubles,
  Footer,
  Header,
  Partners,
  Team,
  About,
  MobileScreenFirst,
  Reasons,
  Email,
} from 'components';

// styles
import './mainPage.scss';

export const MainPage = () => {
  return (
    <div className="container">
      <Header />
      <Reasons />
      <MobileScreenFirst />
      <Troubles />
      <About />
      <Email />
      <Partners />
      <MobileScreenSecond />
      <Team />
      <Footer />
    </div>
  );
};

import AssetsIcon1 from '../../assets/icons/about-us/access.svg';
import AssetsIcon2 from '../../assets/icons/about-us/consultation.svg';
import AssetsIcon3 from '../../assets/icons/about-us/contribution.svg';
import AssetsIcon4 from '../../assets/icons/about-us/dollar.svg';
import AssetsIcon5 from '../../assets/icons/about-us/investment.svg';
import AssetsIcon6 from '../../assets/icons/about-us/passive-income.svg';

export const aboutData = {
  left: [
    { Icon: AssetsIcon1, text: 'Доступ к клубу только для пользователей «60+»' },
    { Icon: AssetsIcon5, text: 'Разумное вложение накопленных средств' },
    { Icon: AssetsIcon2, text: 'Помощь и консультации от специалистов области' },
  ],
  right: [
    { Icon: AssetsIcon4, text: 'Много проверенных объектов для инвестиций' },
    { Icon: AssetsIcon6, text: 'Возможность безопасно получать пассивный доход' },
    { Icon: AssetsIcon3, text: 'Обязательный членский взнос при вступлении' },
  ],
};

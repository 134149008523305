import React, { Dispatch, FC, SetStateAction } from 'react';
import CloseModalIcon from 'assets/icons/email-modal/close-modal.svg';

// styles
import './emailModal.scss';
import { IModalState } from '@components/Email/helper';

interface IEmailModalProps {
  isModalOpen: IModalState;
  setIsModalOpen: Dispatch<SetStateAction<IModalState>>;
}

export const EmailModal: FC<IEmailModalProps> = ({ isModalOpen, setIsModalOpen }) => {

  const isActiveModal = isModalOpen.isOpen ? 'modal active__modal' : 'modal';

  const handleCloseModal = () => {
    setIsModalOpen((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <div className={isActiveModal}>
      <div className="modal__content">
        <div className="modal__header">
          <h2 className="modal__title">60+ App</h2>
          <button onClick={handleCloseModal} className="modal__close-button">
            <img style={{ width: '100%', height: '100%' }} src={CloseModalIcon} alt="close modal button" />
          </button>
        </div>
        <p className="modal__text">{isModalOpen.text}</p>
        <button onClick={handleCloseModal} className="modal__confirmation-button">
          Хорошо
        </button>
      </div>
    </div>
  );
};

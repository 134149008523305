import React from 'react';
import { images } from './mockData';

// styles
import './troubles.scss';

export const Troubles = () => {
  return (
    <section className="troubles">
      <h2 className="troubles__title">Мир полон проблем...</h2>

      <div className="troubles__wrap">
        <div className="content">
          <div className="content__gallery">
            {images.map(({ id, classPrefix }) => (
              <div key={id} className={`gallery-item gallery-item${classPrefix}`} />
            ))}
          </div>

          <div className="content__footer">
            <h3 className="footer__title">Наше приложение поможет найти решение!</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

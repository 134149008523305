import React from 'react';

// styles
import './reasons.scss';

export const Reasons = () => {
  return (
    <section id="reasons" className="reasons">
      <h2 className="reasons__title">Приложение 60+ нужно, чтобы</h2>

      <div className="reasons__info">

        <div className="mailing">
          <div className="mailing__images" />
          <div className="mailing__text">
            <h6 className="mailing__title">Общаться с людьми</h6>
            <p className="mailing__subtitle">из различных сфер, но со схожими ценностями и интересами</p>
          </div>
        </div>

        <div className="gardening">
          <div className="gardening__text">
            <h6 className="gardening__title">Находить клубы по интересам:</h6>
            <p className="gardening__subtitle">
              выращивать цветы, шить одежду или инвестировать в новые проекты — здесь есть все!
            </p>
          </div>
          <div className="gardening__image" />
        </div>

        <div className="hotel">
          <div className="hotel__image" />
          <div className="hotel__text">
            <h6 className="hotel__title">Удачно инвестировать</h6>
            <p className="hotel__subtitle">в надежные объекты и получать пассивный доход</p>
          </div>
        </div>

      </div>
    </section>
  );
};

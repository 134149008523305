import TeamMemberDen from '../../assets/images/team/team-member-den.png';
import TeamMemberMari from '../../assets/images/team/team-member-mari.png';

export const teamMembers = [
  {
    id: 1,
    image: TeamMemberMari,
    name: 'Марина Чубкина',
    position: 'Chief Executive Officer',
    linkTo: 'https://www.linkedin.com/in/marina-chubkina-57898994/',
  },
  {
    id: 2,
    image: TeamMemberDen,
    name: 'Денис Еременко',
    position: 'Chief Technology Officer',
    linkTo: 'https://www.linkedin.com/in/deniseremenko/',
  },
];

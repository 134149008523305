
import { ReactComponent as CoinBase } from '../../assets/images/ourPartners/coinbase.svg';
import { ReactComponent as Spotify } from '../../assets/images/ourPartners/spotify.svg';
import { ReactComponent as Slack } from '../../assets/images/ourPartners/slack.svg';
import { ReactComponent as DropBox } from '../../assets/images/ourPartners/dropbox.svg';
import { ReactComponent as WedFlow } from '../../assets/images/ourPartners/webflow.svg';
import { ReactComponent as Zoom } from '../../assets/images/ourPartners/zoom.svg';


export const partnersLogos = [
  { id: 1, prefix: '_coinbase', },
  { id: 2, prefix: '_spotify', },
  { id: 3, prefix: '_slack', },
  { id: 4, prefix: '_dropbox', },
  { id: 5, prefix: '_webflow', },
  { id: 6, prefix: '_zoom', },
];

export const partnersIcons = [
  { id: 1, Icon: CoinBase },
  { id: 2, Icon: Spotify },
  { id: 3, Icon: Slack },
  { id: 4, Icon: DropBox },
  { id: 5, Icon: WedFlow },
  { id: 6, Icon: Zoom },
];
import React from 'react';
import { MobileScreenFirstSlider } from './MobileScreenFirstSlider/MobileScreenFirstSlider';

// styles
import './mobileScreenFirst.scss';

export const MobileScreenFirst = () => {
  return (
    <>
      <section className="mobile-screenFirst">
        <div className="mobile-screenFirst_item first__screen" />
        <div className="mobile-screenFirst_item second__screen" />
        <div className="mobile-screenFirst_item third__screen" />
      </section>

      <div className="screenFirst-slider">
        <MobileScreenFirstSlider />
      </div>
    </>
  );
};

import React from 'react';
import { aboutData } from './mockData';

// styles
import './about.scss';

export const About = () => {
  return (
    <section id="about" className="about">
      <h2 className="about__title">Инвест Клуб</h2>
      <p className="about__subtitle">Эксклюзивный базовый клуб платформы 60+<br />Принадлежит основателям приложения</p>

      <div className="about__info">
        <div className="info__left">
          {aboutData.left.map(({ Icon, text }) => {
            return (
              <div key={text} className="card">
                <img className="card__icon" alt="about us icon" src={Icon} />
                <p className="card__text">{text}</p>
              </div>
            );
          })}
        </div>

        <div className="info__center" />

        <div className="info__right">
          {aboutData.right.map(({ Icon, text }) => {
            return (
              <div key={text} className="card">
                <img className="card__icon" alt="about us icon" src={Icon} />
                <p className="card__text">{text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

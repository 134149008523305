import React from 'react';
import LinkedinIcon from 'assets/icons/team/linkedin-icon.svg';
import { teamMembers } from './mockData';

//styles
import './team.scss';

export const Team = () => {
  return (
    <section id="team" className="team">
      <h3 className="team__title">Команда проекта</h3>

      <div className="team__members">
        <div className="team__members-wrapper">
          {teamMembers.map(({ id, image, name, position, linkTo }) => {
            return (
              <div key={id} className="team__member-card">
                <div className="logo__wrapper">
                  <img className="logo" src={image} alt="team member logo" />
                  <a href={linkTo} className="social__networking">
                    <img className="m__image" src={LinkedinIcon} alt="team member logo social network icon" />
                  </a>
                </div>
                <p className="name">{name}</p>
                <p className="position">{position}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

import React, { FC } from 'react';
import { links } from '../Header/mockData';

//styles
import './burgerMenu.scss';

interface IBurgerMenuProps {
  isOpen: boolean;
  handleCloseBurgerMenu: () => void;
}

export const BurgerMenu: FC<IBurgerMenuProps> = ({ isOpen = false, handleCloseBurgerMenu }) => {
  const handleClickNavLink = () => {
    handleCloseBurgerMenu();
  };

  const handleScrollToSection = () => {
    const section = document.getElementById('#form');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      handleCloseBurgerMenu();
    }
  };

  return (
    <div className={`burger__menu ${isOpen ? 'active' : 'none'}`}>
      <nav className="nav">
        <ul className="nav__links">
          {links.map(({ name, scrollTo }) => {
            return (
              <li onClick={handleClickNavLink} key={name} className="nav__links">
                <a href={scrollTo} className="nav__links-link">
                  {name}
                </a>
              </li>
            );
          })}
          <button className="nav__button" onClick={handleScrollToSection}>
            Оставить заявку
          </button>
        </ul>
      </nav>
    </div>
  );
};

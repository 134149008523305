import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import { Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { swiperSlides } from './mockData';

// styles
import './mobileScreenFirstSlider.scss';
import 'swiper/css/effect-coverflow';
import 'swiper/css';

export const MobileScreenFirstSlider = () => {
  return (
    <Swiper
      className="screenFirst-slider__mobile-slider"
      effect={'coverflow'}
      pagination={true}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={2}
      initialSlide={1}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        slideShadows: false,
        scale: 0.85,
      }}
      modules={[EffectCoverflow, Pagination]}
    >
      {swiperSlides.map(({ id, namingClass }) => {
        return (
          <SwiperSlide key={id}>
            <div className={namingClass} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
